import { render, staticRenderFns } from "./MmsAdjust.vue?vue&type=template&id=fc27864a&scoped=true&"
import script from "./MmsAdjust.vue?vue&type=script&lang=js&"
export * from "./MmsAdjust.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc27864a",
  null
  
)

export default component.exports